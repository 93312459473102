<template>
  <div v-if="!loading">
    <Card :title="title" :disabled="!editMode">
      <template #close>
        <a @click.prevent="$router.go(-1)">
          <v-btn icon>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </a>
      </template>
      <template #actions>
        <v-btn
          v-permission="{
            permissions: ['channels-update'],
          }"
          @click="editMode = !editMode"
        >
          <span>{{ editMode ? "Görüntüle" : "Düzenle" }}</span>
        </v-btn>
      </template>
      <v-form ref="form" v-model="isFormValid" lazy-validation>
        <v-card flat class="pa-4">
          <v-container>
            <h2 class="mb-4">Bilgiler</h2>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="currentChannel.name"
                  :counter="50"
                  label="Kanal Adı"
                  :rules="[
                    (v) => !!v || 'Kanal adı alanı boş bırakılamaz',
                    (v) =>
                      v?.length >= 3 ||
                      'Kanal adı alanı en az 3 karakterden oluşmalıdır',
                  ]"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="currentChannel.icon"
                  label="Kanal İkonu"
                  :prepend-inner-icon="currentChannel.icon"
                  :rules="[(v) => !!v || 'Kanal ikonu alanı boş bırakılamaz']"
                  append-outer-icon="mdi-magnify"
                  placeholder="mdi-facebook-messenger"
                  hint="Arama ikonuna tıklayarak seçtiğiniz ikonun ismini yukaridaki gibi yazin."
                  required
                  @click:append-outer="openIconDialog"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="d-flex justify-content-end">
                  <v-btn class="mr-3" color="error" @click="reset">
                    İptal
                  </v-btn>
                  <v-btn
                    class="mr-3"
                    :disabled="!isFormValid"
                    color="primary"
                    @click="save"
                  >
                    Kaydet
                  </v-btn>
                  <v-btn
                    color="primary"
                    :disabled="!isFormValid"
                    @click="saveAndClose"
                  >
                    Kaydet ve Kapat
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-form>
    </Card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Card from "@/view/content/components/Card";
import {
  CREATE_CHANNEL,
  UPDATE_CHANNEL,
  GET_CHANNEL,
} from "@/core/services/store/definitions/channels.module";

export default {
  components: { Card },
  data() {
    return {
      editMode: false,
      currentChannel: {},
      loading: !!this.$route.params.id,
      isFormValid: true,
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.getChannel(this.$route.params.id)
        .then(() => {
          this.currentChannel = Object.assign({}, this.channel);
        })
        .finally(() => {
          this.loading = false;
        });
    }

    this.$route.query.editable
      ? (this.editMode = true)
      : (this.editMode = false);
  },
  methods: {
    ...mapActions([GET_CHANNEL, UPDATE_CHANNEL, CREATE_CHANNEL]),
    async save(replaceRoute = true) {
      const valid = this.$refs.form.validate();
      if (!valid) {
        this.isFormValid = false;
        return;
      }
      if (this.$route.params.id) {
        try {
          const channel = JSON.parse(JSON.stringify(this.currentChannel));
          const data = {
            ...channel,
            id: this.$route.params.id,
          };
          await this.updateChannel(data);
          this.$toast.success("Kanal güncellendi", {
            position: "bottom-right",
            duration: 2000,
          });
        } catch (error) {
          this.stopExecution = true;
          this.$toast.error(error[0].detail, {
            position: "bottom-right",
            duration: 2000,
          });
        }
      } else {
        try {
          const channel = await this.createChannel(this.currentChannel);
          if (replaceRoute) {
            this.$router.push({
              name: "def-channels-edit",
              params: {
                id: channel.id,
                query: { editable: true },
              },
            });
          }
          this.$toast.success("Kanal yaratıldı", {
            position: "bottom-right",
            duration: 2000,
          });
        } catch (error) {
          this.stopExecution = true;
          this.$toast.error(error[0].detail, {
            position: "bottom-right",
            duration: 2000,
          });
        }
      }
    },
    async saveAndClose() {
      await this.save(false);
      if (this.isFormValid && !this.stopExecution)
        this.$router.push({ name: "def-channels" });
      this.stopExecution = false;
    },
    reset() {
      if (this.$route.params.id) {
        this.currentChannel = Object.assign({}, this.channel);
      } else {
        this.currentChannel = {};
      }
      this.$router.push({ name: "def-channels" });
    },
    openIconDialog() {
      window.open(
        "https://pictogrammers.github.io/@mdi/font/1.1.34/",
        "_blank"
      );
    },
  },
  computed: {
    ...mapGetters(["channel"]),
    title() {
      if (this.$route.params.id) {
        if (this.editMode) {
          return "Kanal Düzenle";
        }
        return "Kanal Detay";
      }
      return "Kanal Yaratma";
    },
  },
};
</script>

<style></style>
