var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading)?_c('div',[_c('Card',{attrs:{"title":_vm.title,"disabled":!_vm.editMode},scopedSlots:_vm._u([{key:"close",fn:function(){return [_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.$router.go(-1)}}},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)],1)]},proxy:true},{key:"actions",fn:function(){return [_c('v-btn',{directives:[{name:"permission",rawName:"v-permission",value:({
          permissions: ['channels-update'],
        }),expression:"{\n          permissions: ['channels-update'],\n        }"}],on:{"click":function($event){_vm.editMode = !_vm.editMode}}},[_c('span',[_vm._v(_vm._s(_vm.editMode ? "Görüntüle" : "Düzenle"))])])]},proxy:true}],null,false,760617624)},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-card',{staticClass:"pa-4",attrs:{"flat":""}},[_c('v-container',[_c('h2',{staticClass:"mb-4"},[_vm._v("Bilgiler")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"counter":50,"label":"Kanal Adı","rules":[
                  (v) => !!v || 'Kanal adı alanı boş bırakılamaz',
                  (v) =>
                    v?.length >= 3 ||
                    'Kanal adı alanı en az 3 karakterden oluşmalıdır',
                ],"required":""},model:{value:(_vm.currentChannel.name),callback:function ($$v) {_vm.$set(_vm.currentChannel, "name", $$v)},expression:"currentChannel.name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Kanal İkonu","prepend-inner-icon":_vm.currentChannel.icon,"rules":[(v) => !!v || 'Kanal ikonu alanı boş bırakılamaz'],"append-outer-icon":"mdi-magnify","placeholder":"mdi-facebook-messenger","hint":"Arama ikonuna tıklayarak seçtiğiniz ikonun ismini yukaridaki gibi yazin.","required":""},on:{"click:append-outer":_vm.openIconDialog},model:{value:(_vm.currentChannel.icon),callback:function ($$v) {_vm.$set(_vm.currentChannel, "icon", $$v)},expression:"currentChannel.icon"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('v-btn',{staticClass:"mr-3",attrs:{"color":"error"},on:{"click":_vm.reset}},[_vm._v(" İptal ")]),_c('v-btn',{staticClass:"mr-3",attrs:{"disabled":!_vm.isFormValid,"color":"primary"},on:{"click":_vm.save}},[_vm._v(" Kaydet ")]),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.isFormValid},on:{"click":_vm.saveAndClose}},[_vm._v(" Kaydet ve Kapat ")])],1)])],1)],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }